.wrap {
  border: 1px solid #dbe2e6;
  cursor: pointer;
  padding: 0.5rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4rem;
  max-width: 35rem;
  height: 4rem;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
  }
}

.sm {
  max-width: 15rem;
}

.buttonText {
  font-size: 1.5rem;
  white-space: nowrap;
}
