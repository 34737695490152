.card {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  border-radius: 1rem;
  border: 1px solid #ecebeb;

  .loaderEmptyStateContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .emptyState {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        margin-bottom: 2rem;
      }
    }
  }

  .left {
    border-right: 1px solid #ecebeb;
    display: flex;
    flex-direction: column;
    flex-basis: 30%;

    .leftHeader {
      padding: 2rem;
      border-bottom: 1px solid #ecebeb;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .list {
      flex-grow: 1;
      overflow-y: auto;
      max-height: 100%;

      .noteActive {
        background-color: #f4f4f4 !important;
        border-right: 4px solid #0988ff !important;
      }

      .note {
        display: flex;
        border-right: 4px solid transparent;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        border-bottom: 1px solid #ecebeb;
        padding: 1.5rem 2rem;
        gap: 8px;
        cursor: pointer;
        transition: all 0.1s;

        &:hover {
          background-color: #f4f4f4;
        }

        .noteHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .noteAuthor {
            font-size: 1.2rem;
            color: #bfc0c1;
          }

          .noteDate {
            font-size: 1.2rem;
            color: #bfc0c1;
          }
        }

        .noteContent {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .noteTitle {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 340px;
            font-size: 1.6rem;
            font-weight: bolder;
          }

          .noteDescription {
            font-size: 1.4rem;
            overflow: hidden;
            color: #313131;
            display: -webkit-box;
            width: 340px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .leftFooter {
      border-top: 1px solid #ecebeb;
      padding: 0.5rem 2rem;

      span {
        font-size: 1.4rem;
      }
    }
  }

  .right {
    flex-basis: 70%;
    background-color: #f2f2f2;

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .contentHeader {
        display: flex;
        padding: 2rem 4rem;
        border-bottom: 1px solid #c8c8c8;
        flex-direction: column;
        gap: 4px;

        .contentHeaderTitle {
          font-size: 2.6rem;
          font-weight: bolder;
        }

        .additionalInfo {
          display: flex;
          align-items: center;
          gap: 16px;

          span {
            font-size: 1.3rem;
            color: #6a6a6a;
          }
        }
      }

      .contentBody {
        padding: 2rem 4rem;
        height: 100%;
        overflow-y: auto;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}
