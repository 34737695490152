@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

.content {
  width: 100%;
  padding: 2rem 0;
}

.cardContainer {
  width: 70%;
  height: 700px;
}
